:root {
  // User
  // --bg-img: url('../img/bg-orange.svg')
  --bg-img-kota: url("../img/kemenperin-bg-home.png");
  --bg-img-konten: url("../img/kemeperin-bg-konten.png");
  --bg-img-footer: url("../img/kemenperin-logo-footer.svg");
  --bg-img-footer-konten: url("../img/kemenperin-logo-footer-konten.svg");
  --btn-color-primary: #213268;
  --bg-color-primary: #fcc802;
  --btn-color-second: linear-gradient(96.38deg, #f4901e 10.27%, #da5307 72.1%);
  --bg-scrollbar: #0466c9;
  --bg-thumb-scrollbar: #c4c4c4;
  --label-color: #262626;
  --color-card: #0078b7;
  --img-cabang: url("../img/dki-cabang.png");
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
